<script setup lang="ts">
import { useNuxtApp } from 'nuxt/app';

import LinkedText from '~/components/LinkedText.vue';
import useConfig from '~/composables/useConfig.ts';
import useSimpleHead from '~/composables/useSimpleHead.ts';
import GrammarTables from '~/data/pronouns/GrammarTables.vue';
import { buildPronoun } from '~/src/buildPronoun.ts';
import { ExampleCategory, SourceLibrary } from '~/src/classes.ts';
import { examples, pronouns, pronounLibrary } from '~/src/data.ts';

definePageMeta({
    name: 'all',
    path: '/:path(.*)',
});

const { $translator: translator } = useNuxtApp();
const route = useRoute();
const config = useConfig();

const key = computed(() => {
    let url = route.path;
    if (config.pronouns.prefix) {
        if (!url.startsWith(config.pronouns.prefix)) {
            return null;
        }
        url = url.substring(config.pronouns.prefix.length);
    }
    return decodeURIComponent(url.substr(1).replace(/\/$/, ''));
});

const selectedPronoun = config.pronouns.enabled && key
    ? buildPronoun(pronouns, key.value, config, translator)
    : null;

const glue = ` ${translator.translate('pronouns.or')} `;

if (selectedPronoun) {
    useSimpleHead({
        title: `${translator.translate('pronouns.intro')}: ${selectedPronoun.name(glue)}`,
        description: [
            translator.translate('pronouns.examples.header', {}, false),
            translator.translate('pronouns.grammarTable', {}, false),
            translator.translate('sources.headerLong', {}, false),
        ].filter((x) => !!x).join(', '),
        banner: `api/banner${route.path.replace(/\/$/, '')}.png`,
    }, translator);
}

const { data: sources } = useFetch('/api/sources', { lazy: true });

const isNull = key.value?.startsWith(':');

const exampleCategories = ExampleCategory.from(examples, config);
const nameOptions = selectedPronoun ? selectedPronoun.nameOptions() : [];
const pronounGroup = pronounLibrary.find(selectedPronoun);

const comprehensive = useComprehensive();

const sourceLibrary = computed(() => {
    if (sources.value === null) {
        return null;
    }
    return new SourceLibrary(config, sources.value);
});
const groupedSources = computed(() => {
    if (!selectedPronoun || sourceLibrary.value === null || Object.hasOwn(route.query, 'nosources')) {
        return {};
    }

    let key = selectedPronoun.canonicalName;
    if (config.sources.enabled && config.sources.mergePronouns[key] !== undefined) {
        key = config.sources.mergePronouns[key];
    }
    return sourceLibrary.value.getForPronounExtended(key);
});

const addSlash = (link: string) => {
    return link + (['*', '\''].includes(link.substr(link.length - 1)) ? '/' : '');
};

const counter = ref(0);
const counterHandle = ref<number | undefined>();
const counterSpeed = ref(3000);

const setCounterInterval = () => {
    if (counterHandle.value) {
        clearInterval(counterHandle.value);
    }
    if (counterSpeed.value > 0) {
        counter.value++;
        counterHandle.value = setInterval((_) => counter.value++, counterSpeed.value);
    }
};

watch(counterSpeed, () => setCounterInterval());
onMounted(() => setCounterInterval());

const counterPause = () => {
    counterSpeed.value = 0;
};
const counterSlow = () => {
    counterSpeed.value = 3000;
};
const counterFast = () => {
    counterSpeed.value = 1000;
};
</script>

<template>
    <Page>
        <NotFound v-if="!selectedPronoun" />
        <div v-else>
            <h2
                class="d-flex justify-content-between align-items-start align-items-md-center
                    flex-column flex-md-row gap-2"
            >
                <div>
                    <Icon v="tag" />
                    <T>pronouns.intro</T><T>quotation.colon</T>
                </div>
                <ComprehensiveSwitch v-model="comprehensive" />
                <div v-if="nameOptions.length > 1" class="btn-group" role="group">
                    <button :class="['btn btn-sm', counterSpeed === 0 ? 'btn-primary' : 'btn-outline-primary']" @click="counterPause">
                        <Icon v="pause" />
                    </button>
                    <button :class="['btn btn-sm', counterSpeed === 3000 ? 'btn-primary' : 'btn-outline-primary']" @click="counterSlow">
                        <Icon v="play" />
                    </button>
                    <button :class="['btn btn-sm', counterSpeed === 1000 ? 'btn-primary' : 'btn-outline-primary']" @click="counterFast">
                        <Icon v="forward" />
                    </button>
                </div>
            </h2>

            <section>
                <div class="alert alert-primary">
                    <h2 class="text-center mb-0">
                        <template v-if="nameOptions.length === 1">
                            <strong><Spelling escape :text="selectedPronoun.name(glue)" /></strong><small v-if="selectedPronoun.smallForm">/<Spelling :text="selectedPronoun.morphemes[selectedPronoun.smallForm] ?? ''" /></small>
                        </template>
                        <template v-else>
                            <template v-for="(nameOption, i) in nameOptions">
                                <nuxt-link :to="`/${addSlash(nameOption)}`">
                                    <strong>
                                        <Spelling :text="nameOption" escape />
                                    </strong>
                                </nuxt-link>
                                <span v-if="i < nameOptions.length - 1"><Spelling :text="glue" /></span>
                            </template>
                        </template>
                    </h2>
                    <p v-if="selectedPronoun.description" class="h6 small text-center mb-0 mt-2">
                        <em>
                            (<LinkedText
                                escape
                                noicons
                                :text="Array.isArray(selectedPronoun.description)
                                    ? `${$t('pronouns.alt.header')}: ${selectedPronoun.description.join(glue)}`
                                    : selectedPronoun.description"
                            />)
                        </em>
                    </p>
                </div>
            </section>

            <section>
                <h2 class="h4">
                    <Icon v="file-signature" />
                    <T>pronouns.examples.header</T><T>quotation.colon</T>
                </h2>

                <ul>
                    <template v-for="exampleCategory in exampleCategories">
                        <ExampleCategoryListItem
                            v-if="!exampleCategory.comprehensive || comprehensive"
                            :example-category="exampleCategory"
                            :pronouns-choice="[selectedPronoun]"
                            :counter="counter"
                            pronunciation
                        />
                    </template>
                </ul>
            </section>

            <CensusStat type="pronouns" :item="selectedPronoun.name(glue)" colour="info" />

            <section v-if="selectedPronoun.history">
                <template v-for="part in selectedPronoun.history.replace(/\\@/g, '###').split('@')">
                    <template v-if="part === '__generator__'">
                        <div v-if="config.pronouns.generator?.disclaimer ?? true" class="alert alert-warning">
                            <Icon v="exclamation-triangle" />
                            <T>pronouns.generated</T>
                        </div>
                    </template>
                    <div v-else class="alert alert-light">
                        <Icon v="info-circle" />
                        <LinkedText :text="part.replace(/###/g, '@')" noicons />
                    </div>
                </template>
            </section>

            <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

            <GrammarTables :selected-pronoun="selectedPronoun" :comprehensive="comprehensive" :counter="counter" />

            <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />

            <PronounGroup
                v-if="pronounGroup"
                :pronoun-group="pronounGroup.group"
                :pronouns="pronounGroup.groupPronouns"
            />

            <Avoiding v-if="isNull" />

            <section>
                <Share :title="`${$t('pronouns.intro')}: ${selectedPronoun.name(glue)}`" />
            </section>

            <section v-if="Object.values(groupedSources).filter(x => !!x).length">
                <Literature :pronoun="selectedPronoun" :sources="groupedSources" />
            </section>

            <AdPlaceholder :phkey="['content-2', 'content-mobile-2']" />

            <Separator icon="info" />
            <section class="mb-0">
                <h2 class="h4">
                    <Icon v="info-circle" />
                    <T>home.whatisit</T>
                </h2>
                <T>home.about</T>
                <Homepage align="center" />
            </section>
        </div>
    </Page>
</template>
